import React, { Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Dashboard from './dashboard';
import Agendas from './agendas';
import Caprinis from './caprinis';
import CatalogosCirugias from './catalogosCirugias';
import CategoriasInsumos from './categoriasInsumos';
import CategoriasProveedores from './categoriasProveedores';
import Cirugias from './cirugias';
import CotizacionesInsumos from './cotizacionesInsumos';
import Graficas from './graficas';
import EntradasSalidas from './entradasSalidas';
import HistoriasClinicas from './historiasClinicas';
import IngredientesActivos from './ingredientesActivos';
import Insumos from './insumos';
import NotasEgreso from './notasEgreso';
import NotasIngreso from './notasIngreso';
import NotasPostOperatorias from './notasPostOperatorias';
import NotasPostOperatoriasExternas from './notasPostOperatoriasExternas';
import Pacientes from './pacientes';
import PreciosProveedoresInsumos from './preciosProveedoresInsumos';
import Proveedores from './proveedores';
import ResumenesQuirurgicos from './resumenesQuirurgicos';
import Roles from './roles';
import Usuarios from './usuarios';
import ValoracionesPreQuirurgicas from './valoracionesPreQuirurgicas';
import ValoracionesPreQuirurgicas2 from './valoracionesPreQuirurgicas2';

import withAdminLayout from '../../layout/withAdminLayout';

const Admin = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={path} component={Dashboard} />

        <Route path={`${path}/agendas`} component={Agendas} />
        <Route path={`${path}/caprinis`} component={Caprinis} />
        <Route path={`${path}/catalogos_cirugias`} component={CatalogosCirugias} />
        <Route path={`${path}/categorias_insumos`} component={CategoriasInsumos} />
        <Route path={`${path}/categorias_proveedores`} component={CategoriasProveedores} />
        <Route path={`${path}/cirugias`} component={Cirugias} />
        <Route path={`${path}/cotizaciones_insumos`} component={CotizacionesInsumos} />
        <Route path={`${path}/graficas`} component={Graficas} />
        <Route path={`${path}/entradas_salidas`} component={EntradasSalidas} />
        <Route path={`${path}/historias_clinicas`} component={HistoriasClinicas} />
        <Route path={`${path}/ingredientes_activos`} component={IngredientesActivos} />
        <Route path={`${path}/insumos`} component={Insumos} />
        <Route path={`${path}/notas_egreso`} component={NotasEgreso} />
        <Route path={`${path}/notas_ingreso`} component={NotasIngreso} />
        <Route path={`${path}/notas_post_operatorias`} component={NotasPostOperatorias} />
        <Route path={`${path}/notas_post_operatorias_externas`} component={NotasPostOperatoriasExternas} />
        <Route path={`${path}/pacientes`} component={Pacientes} />
        <Route path={`${path}/precios_proveedores_insumos`} component={PreciosProveedoresInsumos} />
        <Route path={`${path}/proveedores`} component={Proveedores} />
        <Route path={`${path}/resumenes_quirurgicos`} component={ResumenesQuirurgicos} />
        <Route path={`${path}/roles`} component={Roles} />
        <Route path={`${path}/usuarios`} component={Usuarios} />
        <Route path={`${path}/valoraciones_pre_quirurgicas`} component={ValoracionesPreQuirurgicas} />
        <Route path={`${path}/valoraciones_pre_quirurgicas_2`} component={ValoracionesPreQuirurgicas2} />
        
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin);
