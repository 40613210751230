import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { API, Endpoints } from '../config/api/Api';

const { SubMenu } = Menu;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [menu, setMenu] = useState({
    agenda: [
      base => 0,
      mensual => 0
    ],
    categorias: [
      base => 0,
      insumos => 0,
      proveedores => 0
    ],
    cirugias: [
      base => 0,
      nueva => 0,
      cirugias => 0,
      catalogos => 0,
      graficas => 0
    ],
    graficas: [
      base => 0,
      cirugias => 0,
      insumos => 0
    ],
    insumos: [
      base => 0,
      insumos => 0,
      ingredientesActivos => 0,
      cotizacionesCompras => 0,
      entradasSalidas => 0,
      reporteCaducidades => 0
    ],
    pacientes: [
      base => 0,
      nuevo => 0,
      pacientes => 0
    ],
    proveedores: [
      base => 0,
      nuevo => 0,
      proveedores => 0
    ],
    roles: [
      base => 0,
      nuevo => 0,
      roles => 0
    ],
    usuarios: [
      base => 0,
      nuevo => 0,
      usuarios => 0
    ]
  });

  const cargarMenu = () => {
    API.post(Endpoints.usuario.menu ).then(response => {
      if (response.data.success) {
        setMenu(response.data.data);
      }
    });
  };

  useEffect(cargarMenu, []);

  return (
    <Menu
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      selectedKeys={
        // eslint-disable-next-line
        [location.pathname]
      }
    >
      <Menu.Item key={`${path}`} icon={!topMenu && <FeatherIcon icon="home" />}>
        <NavLink onClick={toggleCollapsed} to={`${path}`}>
          Inicio
        </NavLink>
      </Menu.Item>
      
      { menu.agenda.base ? ( <>
        <SubMenu key="agenda" icon={!topMenu && <FeatherIcon icon="watch" />} title="Agenda">
          { menu.agenda.mensual ? ( <>
            <Menu.Item key={`${path}/agendas/mensuales`}>
              <NavLink onClick={toggleCollapsed} to={`${path}/agendas/mensuales`}>
                Mensual
              </NavLink>
            </Menu.Item>
          </> ) : (<></>)}
        </SubMenu>
      </> ) : (<></>) }

      { menu.categorias.base ? ( <>
        <SubMenu key="categorias" icon={!topMenu && <FeatherIcon icon="tag" />} title="Categorías">
          { menu.categorias.insumos ? ( <>
            <Menu.Item key={`${path}/categorias_insumos`}>
              <NavLink onClick={toggleCollapsed} to={`${path}/categorias_insumos`}>
                Categorías de Insumos
              </NavLink>
            </Menu.Item>
          </> ) : (<></>)}
          { menu.categorias.proveedores ? ( <>
            <Menu.Item key={`${path}/categorias_proveedores`}>
              <NavLink onClick={toggleCollapsed} to={`${path}/categorias_proveedores`}>
                Categorías de Proveedores
              </NavLink>
            </Menu.Item>
          </> ) : (<></>)}
        </SubMenu>
      </> ) : (<></>) }
      
      { menu.cirugias.base ? ( <>
        <SubMenu key="cirugias" icon={!topMenu && <FeatherIcon icon="heart" />} title="Cirugías">
          { menu.cirugias.nueva ? ( <>
            <Menu.Item key={`${path}/cirugias/nuevo`}>
              <NavLink onClick={toggleCollapsed} to={`${path}/cirugias/nuevo`}>
                Nueva cirugía
              </NavLink>
            </Menu.Item>
          </> ) : (<></>)}
          { menu.cirugias.cirugias ? ( <>
            <Menu.Item key={`${path}/cirugias`}>
              <NavLink onClick={toggleCollapsed} to={`${path}/cirugias`}>
                Cirugías
              </NavLink>
            </Menu.Item>
          </> ) : (<></>)}
          { menu.cirugias.catalogos ? ( <>
            <Menu.Item key={`${path}/catalogos_cirugias`}>
              <NavLink onClick={toggleCollapsed} to={`${path}/catalogos_cirugias`}>
                Catálogo de cirugías
              </NavLink>
            </Menu.Item>
          </> ) : (<></>)}
        </SubMenu>
      </> ) : (<></>) }
      
      { menu.graficas.base ? ( <>
        <SubMenu key="graficas" icon={!topMenu && <FeatherIcon icon="pie-chart" />} title="Gráficas">
          { menu.graficas.cirugias ? ( <>
            <Menu.Item key={`${path}/graficas/cirugias`}>
              <NavLink onClick={toggleCollapsed} to={`${path}/graficas/cirugias`}>
                Cirugías
              </NavLink>
            </Menu.Item>
          </> ) : (<></>)}
          { menu.graficas.insumos ? ( <>
            <Menu.Item key={`${path}/graficas/insumos`}>
              <NavLink onClick={toggleCollapsed} to={`${path}/graficas/insumos`}>
                Insumos
              </NavLink>
            </Menu.Item>
          </> ) : (<></>)}
        </SubMenu>
      </> ) : (<></>) }

      { menu.insumos.base ? ( <>
        <SubMenu key="insumos" icon={!topMenu && <FeatherIcon icon="package" />} title="Insumos">
          { menu.insumos.insumos ? ( <>
            <Menu.Item key={`${path}/insumos`}>
              <NavLink onClick={toggleCollapsed} to={`${path}/insumos`}>
                Insumos
              </NavLink>
            </Menu.Item>
          </> ) : (<></>)}
          { menu.insumos.ingredientesActivos ? ( <>
            <Menu.Item key={`${path}/ingredientes_activos`}>
              <NavLink onClick={toggleCollapsed} to={`${path}/ingredientes_activos`}>
                Ingredientes activos
              </NavLink>
            </Menu.Item>
          </> ) : (<></>)}
          { menu.insumos.cotizacionesCompras ? ( <>
            <Menu.Item key={`${path}/cotizaciones_insumos`}>
              <NavLink onClick={toggleCollapsed} to={`${path}/cotizaciones_insumos`}>
                Cotizaciones y compras
              </NavLink>
            </Menu.Item>
          </> ) : (<></>)}
          { menu.insumos.entradasSalidas ? ( <>
            <Menu.Item key={`${path}/entradas_salidas`}>
              <NavLink onClick={toggleCollapsed} to={`${path}/entradas_salidas`}>
                Entradas / Salidas
              </NavLink>
            </Menu.Item>
          </> ) : (<></>)}
          { menu.insumos.reporteCaducidades ? ( <>
            <Menu.Item key={`${path}/entradas_salidas/caducidades`}>
              <NavLink onClick={toggleCollapsed} to={`${path}/entradas_salidas/caducidades`}>
                Reporte de caducidades
              </NavLink>
            </Menu.Item>
          </> ) : (<></>)}
        </SubMenu>
      </> ) : (<></>) }

      { menu.pacientes.base ? ( <>
        <SubMenu key="pacientes" icon={!topMenu && <FeatherIcon icon="user-plus" />} title="Pacientes">
        { menu.pacientes.nuevo ? ( <>
          <Menu.Item key={`${path}/pacientes/nuevo`}>
            <NavLink onClick={toggleCollapsed} to={`${path}/pacientes/nuevo`}>
              Nuevo paciente
            </NavLink>
          </Menu.Item>
          </> ) : (<></>)}
          { menu.pacientes.pacientes ? ( <>
          <Menu.Item key={`${path}/pacientes`}>
            <NavLink onClick={toggleCollapsed} to={`${path}/pacientes`}>
              Pacientes
            </NavLink>
          </Menu.Item>
          </> ) : (<></>)}
        </SubMenu>
      </> ) : (<></>) }

      { menu.proveedores.base ? ( <>
        <SubMenu key="proveedores" icon={!topMenu && <FeatherIcon icon="truck" />} title="Proveedores">
        { menu.proveedores.nuevo ? ( <>
          <Menu.Item key={`${path}/proveedores/nuevo`}>
            <NavLink onClick={toggleCollapsed} to={`${path}/proveedores/nuevo`}>
              Nuevo proveedor
            </NavLink>
          </Menu.Item>
          </> ) : (<></>)}
          { menu.proveedores.proveedores ? ( <>
          <Menu.Item key={`${path}/proveedores`}>
            <NavLink onClick={toggleCollapsed} to={`${path}/proveedores`}>
              Proveedores
            </NavLink>
          </Menu.Item>
          </> ) : (<></>)}
        </SubMenu>
      </> ) : (<></>) }

      { menu.roles.base ? ( <>
        <SubMenu key="roles" icon={!topMenu && <FeatherIcon icon="slack" />} title="Roles">
        { menu.roles.nuevo ? ( <>
          <Menu.Item key={`${path}/roles/nuevo`}>
            <NavLink onClick={toggleCollapsed} to={`${path}/roles/nuevo`}>
              Nuevo rol
            </NavLink>
          </Menu.Item>
          </> ) : (<></>)}
          { menu.roles.roles ? ( <>
          <Menu.Item key={`${path}/roles`}>
            <NavLink onClick={toggleCollapsed} to={`${path}/roles`}>
              Roles
            </NavLink>
          </Menu.Item>
          </> ) : (<></>)}
        </SubMenu>
      </> ) : (<></>) }

      { menu.usuarios.base ? ( <>
        <SubMenu key="usuarios" icon={!topMenu && <FeatherIcon icon="users" />} title="Usuarios">
        { menu.usuarios.nuevo ? ( <>
          <Menu.Item key={`${path}/usuarios/nuevo`}>
            <NavLink onClick={toggleCollapsed} to={`${path}/usuarios/nuevo`}>
              Nuevo usuario
            </NavLink>
          </Menu.Item>
          </> ) : (<></>)}
          { menu.usuarios.usuarios ? ( <>
          <Menu.Item key={`${path}/usuarios`}>
            <NavLink onClick={toggleCollapsed} to={`${path}/usuarios`}>
              Usuarios
            </NavLink>
          </Menu.Item>
          </> ) : (<></>)}
        </SubMenu>
      </> ) : (<></>) }

    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
