import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Agendas = lazy(() => import('../../container/agendas/Agendas'));
const Mensuales = lazy(() => import('../../container/agendas/Mensuales'));
const Diarias = lazy(() => import('../../container/agendas/Diarias'));

const AgendasRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Agendas} />
      <Route exact path={`${path}/mensuales`} component={Mensuales} />
      <Route exact path={`${path}/diarias/:atendidaPor/:fechaInicial`} component={Diarias} />
    </Switch>
  );
};

export default AgendasRoutes;